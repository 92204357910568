.selectFieldService .ServiceRequestText,
.selectFieldService .selectService {
  display: flex;
  display: block !important;
}

.selectFieldService
  .ServiceRequestText
  .ddl-from-custom-container:not(:first-child) {
  margin-left: 50px;
}

.selectFieldService .ServiceRequestText {
  margin-bottom: 50px;
}
.selectFieldService .ServiceRequestText .ServiceRequestText1 {
  min-width: 300px;
  max-width: 600px;
}
.selectFieldService .dateDiv {
  display: flex;
  margin: 26px 0px 0px 0px;
}
.selectFieldService .dateDiv .ddl-from-custom-label {
  margin-bottom: 0.5rem !important;
}
.selectFieldService .dateDiv div .react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #037653;
}
.selectFieldService .dateDiv .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #00573D;
}
.selectFieldService .dateDiv div span {
  display: flex;
  align-items: center;
}

.selectFieldService .dateDiv .calenderOpt {
  margin: 32px 0px 0px -30px;
}

.selectFieldService .ServiceRequestText .ServiceRequestText1 {
  float: right;
  div span:last-of-type {
    display: block !important;
  }
}

.selectFieldService .ServiceRequestText .SiteServiceDate {
  display: flex;
  flex-direction: column;
}
.selectFieldService .endUserDetails .ddl-from-custom-container {
  margin: 25px 0px;
}

.endUserServiceRequestInfo {
  display: flex;
  flex-direction: column;
}

.endUserInfoMenu {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  flex-wrap: wrap; /* Allow wrapping if needed */
  gap: 10px; /* Add some space between the inputs */
}

.endUserInfoMenu > * {
  flex: 1; /* Make each input take equal space */
  min-width: 150px; /* Minimum width for each input */
}

@media screen and (max-width: 768px) {
  .selectFieldService .ServiceRequestText,
  .selectFieldService .selectService {
    display: flex;
  }
  .selectFieldService .ServiceRequestText .dateDiv {
    margin-top: 30px;
  }
  .selectFieldService .ServiceRequestText .SiteServiceDate {
    display: block;
    margin-top: 20px;
  }
}
