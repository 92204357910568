.deviceServiceRequest .deviceServiceRequestBox .selectBoxdevice {
  margin-bottom: 30px;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
}
.deviceServiceRequest
  .deviceServiceRequestBox
  .selectBoxdevice
  .ddl-form-custom-container {
  margin-bottom: 30px;
}
.deviceServiceRequest
  .deviceServiceRequestBox
  .selectBoxdevice
  .ddl-form-custom-container:last-child
  .ddl-form-custom-label {
  margin-bottom: 15px;
}
.deviceServiceRequest
  .deviceServiceRequestBox
  .selectBoxdevice
  .ddl-form-custom-container:last-child
  .ddl-form-custom-label-container {
  align-items: flex-start;
}
.deviceServiceRequest
  .deviceServiceRequestBox
  .selectBoxdevice
  .ddl-form-custom-container:last-child {
  min-width: 300px;
}

.deviceServiceRequest
  .deviceServiceRequestBox
  .selectBoxdevice
  .dateDivReturn div .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: #037653;
  }

.deviceServiceRequest
  .deviceServiceRequestBox
  .selectBoxdevice
  .dateDivReturn .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #00573D;
  }
  
.deviceServiceRequest .deviceServiceRequestBox table {
  width: 95%;
}
.deviceServiceRequest .deviceServiceRequestBox table .deleteIcon {
  margin: 0px 0px 0px 15px;
  width: 20px;
}
.deviceServiceRequest .selectBoxdevice .siteDetail {
  min-width: 470px;
  margin-right: 40px;
}
.theadCssOverride th {
  border-bottom: none !important;
  position: sticky;
  top: 0;
  background-color: white;
}

.scroll-bar-container {
	overflow-y: auto;
	height: 290px;
	overflow-x: hidden;
}

.scroll-bar-container.hide-scrollbar {
  overflow-y: hidden;
}

.deviceServiceRequest .systemSelectionMenu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 700px;
  justify-content: space-between;
}
.deviceServiceRequest .systemSelectionMenu .app-container-select-width {
  margin-right: 0px;
}
.butonContinue {
  margin-top: 50px;
}
.field-required-span {
  color: red; /* Color of the asterisk */
  display: inline; /* Ensure it is displayed inline */
  font-size: 16px;
  padding: 0px;
  margin: -1px 0px 0px -9px;
}
.coloumn-design {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
.custom-alert {
  width: auto;
  display: inline-block; /* Ensures the alert width adjusts to the content */
  white-space: normal; /* Allows text to wrap if necessary */
  margin-bottom: 20px;
}

.date-picker-container {
  display: flex;
  align-items: center;
}

.calendar-icon {
  margin-left: 8px;
  cursor: pointer;
}

/* @media screen and (max-width: 768px) {
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    display: none;
  }
} */
