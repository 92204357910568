.LandingPage {
  padding: 30px 40px;
}
.LandingPage .ServiceRequestBox .serviceType {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.LandingPage .ServiceRequestBox .serviceType div {
  border-radius: 8px;
  border: 1px solid #c9cacc;
  min-width: 300px;
  height: 56px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: "Noto Sans", Helvetica, Arial, sans-serif !important;
  padding: 15px;
  font-size: 18px;
  font-weight: 500;
  margin-right: 30px;
  -webkit-box-shadow: 4px 4px 10px -4px rgba(0, 0, 0, 0.81);
  -moz-box-shadow: 4px 4px 10px -4px rgba(0, 0, 0, 0.81);
  box-shadow: 4px 4px 10px -4px rgba(0, 0, 0, 0.81);
  cursor: pointer;
  animation-timing-function: linear;
  margin-bottom: 30px;
}
.LandingPage .ServiceRequestBox .serviceType div:hover {
  scale: 1.01;
  color: var(--ddl-color--primary-emerson-green-dark);
  border: 1px solid var(--ddl-color--primary-emerson-green-dark);
}
.LandingPage .ServiceRequestBox .serviceType div:hover img {
  -webkit-filter: opacity(0.5) drop-shadow(0 0 0 #00ffa3);
  filter: opacity(0.5) drop-shadow(0 0 0 #00ffa3);
}
.LandingPage .ServiceRequestBox .serviceType div img {
  margin-right: 20px;
}
.LandingPage .mainContent .ddl-text--h2 {
  margin-bottom: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.returnToHome {
  cursor: pointer;
  color: #00573D; /* Change color as needed */
  font-size: 14px; /* Example font size */
  font-weight: bold;
}

.label-text-gap {
  margin-bottom: 16px; /* Adjust the value as needed */
}

